/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Layout,
  Menu,
  useGetIdentity,
  useLogout,
  useSidebarState,
} from 'react-admin';
import { Link, To, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import BullWorkerIcon from '@mui/icons-material/DynamicForm';
import LockIcon from '@mui/icons-material/Lock';
import QRCode from '@mui/icons-material/QrCode2';
import Calendar from '@mui/icons-material/InsertInvitation';
import { TbMessage, TbPolaroidFilled, TbTagStarred } from 'react-icons/tb';

import { useAdminRoles } from '@hooks/useAdminRoles';
import Symbol from '@components/svgs/logos/symbol';
import {
  ActionIcon,
  AdminUserIcon,
  AnalyticsIcon,
  BadgeIcon,
  CampaignIcon,
  CauseIcon,
  CRMOrganizationIcon,
  CRMPersonIcon,
  CRMTagIcon,
  DonationIcon,
  FeatureFlagIcon,
  InAppNotificationsIcon,
  MissionIcon,
  PillIcon,
  TopicIcon,
  UserIcon,
} from '@components/icons';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import { httpClient } from './dataProvider';
import Config from './config';

const MenuItemIcon = ({ icon }: any) =>
  React.cloneElement(icon, {
    size: FontStyle.sizeBig,
  });

type CustomMenuTitleProps = {
  title: string;
  compact: boolean;
  roles?: string[];
};
const CustomMenuTitle = ({ title, compact, roles }: CustomMenuTitleProps) => {
  const hasPermission = useAdminRoles(roles || []);
  if (!hasPermission) {
    return <></>;
  }
  return (
    <div
      style={{
        marginTop: SpacingStyle.normal,
        marginBottom: SpacingStyle[4],
        marginLeft: SpacingStyle.big,
        fontSize: FontStyle.sizeVerySmall,
        fontWeight: 600,
        color: Colors.Grey[400],
        textTransform: 'uppercase',
        cursor: 'default',
        height: compact ? '10px' : 'auto',
      }}
    >
      {!compact && title}
    </div>
  );
};

type CustomMenuItemProps = {
  to?: To;
  onClick?: () => void;
  icon: React.ReactNode;
  label: string;
  compact: boolean;
  roles: string[];
};
const CustomMenuItem = ({
  to,
  icon,
  label,
  compact,
  roles,
  onClick,
  ...props
}: CustomMenuItemProps) => {
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const location = useLocation();
  const hasPermission = useAdminRoles(roles || []);

  useEffect(() => {
    const mainPath = location.pathname.split('/')[1];
    setActive(`/${mainPath}` === to);
  }, [location.pathname, to]);

  const width = useMemo(() => {
    if (compact) {
      return '43px';
    }
    return 'auto';
  }, [compact]);

  const backgroundColor = useMemo(() => {
    if (active) {
      return Colors.Magenta[100];
    }
    if (hovered) {
      return Colors.Magenta[50];
    }
    return 'transparent';
  }, [active, hovered]);

  const marginVertical = useMemo(() => {
    if (compact) {
      return SpacingStyle[4];
    }
    return SpacingStyle[16];
  }, [compact]);

  const color = useMemo(() => {
    if (active) {
      return Colors.Magenta.primary;
    }
    return Colors.Grey[800];
  }, [active]);

  const Component = to ? Link : ('div' as any);

  if (!hasPermission) {
    return <></>;
  }
  return (
    <Component
      to={to}
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        width,
        margin: `${SpacingStyle[2]}px ${marginVertical}px`,
        padding: `${SpacingStyle[8]}px ${SpacingStyle[12]}px`,
        color,
        fontSize: FontStyle.sizeSmall,
        fontWeight: '500',
        borderRadius: BorderStyle.Radius.normal,
        gap: SpacingStyle[8],
        backgroundColor,
        cursor: 'pointer',
        textDecoration: 'none',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      {icon && <MenuItemIcon icon={icon} />}
      {!compact && label}
    </Component>
  );
};

const AdminUserFooter = ({ compact }: { compact: boolean }) => {
  const { data, isLoading, error } = useGetIdentity();
  const logout = useLogout();

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <div
      style={{
        padding: `${SpacingStyle[12]}px ${SpacingStyle[16]}px`,
        fontSize: FontStyle.sizeVerySmall,
        fontWeight: '500',
        color: Colors.Grey[400],
        textTransform: 'uppercase',
      }}
    >
      {!compact && <div>{data?.username}</div>}
      <div
        onClick={handleLogout}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: SpacingStyle[8],
          cursor: 'pointer',
          marginTop: SpacingStyle[4],
        }}
      >
        <LockIcon
          style={{
            fontSize: compact ? FontStyle.sizeMedium : FontStyle.sizeVerySmall,
          }}
        />
        {!compact && 'logout'}
      </div>
    </div>
  );
};

export const MyMenu = ({ ...props }) => {
  const [_open, setOpen] = useSidebarState();
  const isMediumScreen = useMediaQuery('(max-width: 1080px)');

  useEffect(() => {
    setOpen(!isMediumScreen);
  }, [isMediumScreen, setOpen]);

  /**
   * Redirect to BullBoard
   */
  const redirectToBullBoard = async () => {
    const {
      data: { redirectTo },
    } = await httpClient(`${Config.API_URL}/bullboard/jwt`).then(({ json }) => ({
      data: json.data,
    }));
    if (redirectTo) {
      window.open(redirectTo, '_blank');
    }
  };

  return (
    <Menu
      {...props}
      style={{
        justifyContent: 'space-between',
      }}
    >
      <div style={{ flex: 1 }}>
        <a
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle.small,
            marginTop: SpacingStyle.normal,
            marginLeft: isMediumScreen ? SpacingStyle[12] : SpacingStyle.big,
            marginBottom: isMediumScreen ? SpacingStyle[12] : 'auto',
          }}
          href="#"
        >
          <Symbol height={30} width={30} fill={Colors.Magenta.primary} />
          {window.location.hostname === 'localhost' && 'localhost'}
        </a>

        {/* Campaigning */}
        <CustomMenuTitle title="Campaigning" compact={isMediumScreen} />
        <CustomMenuItem
          to="/campaigns"
          label="Campaigns"
          icon={<MenuItemIcon icon={<CampaignIcon />} />}
          compact={isMediumScreen}
          roles={[
            'super_admin',
            'campaign_admin',
            'campaign_manager',
            'product',
            'read_only',
          ]}
        />
        <CustomMenuItem
          to="/missions"
          label="Missions"
          icon={<MenuItemIcon icon={<MissionIcon />} />}
          compact={isMediumScreen}
          roles={[
            'super_admin',
            'campaign_admin',
            'campaign_manager',
            'product',
            'read_only',
          ]}
        />
        <CustomMenuItem
          to="/actions"
          label="Actions"
          icon={<MenuItemIcon icon={<ActionIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />

        {/* Contact Directory */}
        <CustomMenuTitle title="Contact Directory" compact={isMediumScreen} />
        <CustomMenuItem
          to="/crm_organizations"
          label="Organizations"
          icon={<MenuItemIcon icon={<CRMOrganizationIcon />} />}
          compact={isMediumScreen}
          roles={[
            'super_admin',
            'campaign_admin',
            'campaign_manager',
            'product',
            'read_only',
          ]}
        />
        <CustomMenuItem
          to="/crm_persons"
          label="Persons"
          icon={<MenuItemIcon icon={<CRMPersonIcon />} />}
          compact={isMediumScreen}
          roles={[
            'super_admin',
            'campaign_admin',
            'campaign_manager',
            'product',
            'read_only',
          ]}
        />
        <CustomMenuItem
          to="/crm_tags"
          label="Tags"
          icon={<MenuItemIcon icon={<CRMTagIcon />} />}
          compact={isMediumScreen}
          roles={[
            'super_admin',
            'campaign_admin',
            'campaign_manager',
            'product',
            'read_only',
          ]}
        />

        {/* Product */}
        <CustomMenuTitle
          title="Product"
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product', 'read_only']}
        />
        <CustomMenuItem
          to="/product_analytics"
          label="Analytics"
          icon={<MenuItemIcon icon={<AnalyticsIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/qr_code_generator"
          label="QR Code Generator"
          icon={<MenuItemIcon icon={<QRCode />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/badges"
          label="Badges"
          icon={<MenuItemIcon icon={<BadgeIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/causes"
          label="Causes"
          icon={<MenuItemIcon icon={<CauseIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin']}
        />
        <CustomMenuItem
          to="/feature_flags"
          label="Feature Flags"
          icon={<MenuItemIcon icon={<FeatureFlagIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'product']}
        />
        <CustomMenuItem
          to="/in_app_notifications"
          label="In-app notifs"
          icon={<MenuItemIcon icon={<InAppNotificationsIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/pepper_pills"
          label="Pepper Pills"
          icon={<MenuItemIcon icon={<PillIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/topics"
          label="Topics"
          icon={<MenuItemIcon icon={<TopicIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin']}
        />
        <CustomMenuItem
          onClick={redirectToBullBoard}
          label="Workers"
          icon={<MenuItemIcon icon={<BullWorkerIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'product']}
        />

        {/* Shop */}
        <CustomMenuTitle
          title="Shop"
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/shop"
          label="Calendar"
          icon={<MenuItemIcon icon={<Calendar />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/donations"
          label="Donations"
          icon={<MenuItemIcon icon={<DonationIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/deals"
          label="Deals"
          icon={<MenuItemIcon icon={<BadgeIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />

        {/* Profile */}
        <CustomMenuTitle
          title="Profile"
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product', 'read_only']}
        />
        <CustomMenuItem
          to="/profile_skills"
          label="Skills"
          icon={<MenuItemIcon icon={<TbPolaroidFilled />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/profile_tags"
          label="Tags"
          icon={<MenuItemIcon icon={<TbTagStarred />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
        <CustomMenuItem
          to="/profile_prompts"
          label="Prompts"
          icon={<MenuItemIcon icon={<TbMessage />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />

        {/* People */}
        <CustomMenuTitle
          title="People"
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product', 'read_only']}
        />
        <CustomMenuItem
          to="/admin_users"
          label="Admins"
          icon={<MenuItemIcon icon={<AdminUserIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin']}
        />
        <CustomMenuItem
          to="/users"
          label="Users"
          icon={<MenuItemIcon icon={<UserIcon />} />}
          compact={isMediumScreen}
          roles={['super_admin', 'campaign_admin', 'product']}
        />
      </div>
      <AdminUserFooter compact={isMediumScreen} />
    </Menu>
  );
};

const MyAppBar = () => <AppBar style={{ display: 'none' }} />;

export const MyLayout = ({ ...props }) => (
  <Layout {...props} menu={MyMenu} appBar={MyAppBar} />
);
