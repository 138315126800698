import React from 'react';
import { Edit, useNotify } from 'react-admin';

import { convertFileToBase64 } from '@services/file';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import DealForm from './components/deal_form';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `deals/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" title="Deals" />;

export const DealEdit = () => {
  const notify = useNotify();

  const handleSave = async (data: any) => {
    if (data.avatar && data.avatar.rawFile) {
      data.avatar = await convertFileToBase64(data.avatar.rawFile);
    }

    if (data.thumbnail && data.thumbnail.rawFile) {
      data.thumbnail = await convertFileToBase64(data.thumbnail.rawFile);
    }

    notify('Deal saved successfully!', { type: 'success' });
    return data;
  };

  return (
    <Edit
      redirect={redirectAfterCreateEdit}
      mutationMode="pessimistic"
      actions={EditActions}
      transform={handleSave}
    >
      <DealForm mode="edit" />
    </Edit>
  );
};
