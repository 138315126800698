import React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { ActionCTAEmailType, ActionCTAPhoneCallType } from 'types/action';
import { AdminUserType } from 'types/admin_user';
import { CauseType } from 'types/cause';
import { CRMOrganizationType } from 'types/crm_organization';
import { CRMPersonType } from 'types/crm_person';
import { CRMTagType } from 'types/crm_tag';
import { MissionType } from 'types/mission';
import { VideoScriptType } from 'types/video_script';
import { VideoType } from 'types/video';
import { WinType } from 'types/win';
import { ProfilePromptType, ProfileSkillType, ProfileTagType } from 'types/profile';
import { DonationType } from 'types/donation';
import { Deal } from 'types/deal';

import { useReloadPage } from '@hooks/useReloadPage';
import {
  isCampaignAdmin,
  // isCampaignManager,
  isProduct,
  isReadOnly,
  isSuperAdmin,
} from '@hooks/useAdminRoles';
import ellipsis from '@services/ellipsis';
import { dateFormatter } from '@services/date';
import queryClient from '@modules/query_client';
import { ActionVideoStudio } from '@components/video_studios/action_video_studio';
import Reloading from '@containers/reloading';
import CampaignsAnalytics from '@containers/campaigns_analytics';
import { AdminUserList } from '@models/admin_users/list';
import { AdminUserShow } from '@models/admin_users/show';
import { AdminUserCreate } from '@models/admin_users/create';
import { AdminUserEdit } from '@models/admin_users/edit';
import { AdminUserCampaignList } from '@models/admin_users_campaigns/list';
import { AdminUserCampaignShow } from '@models/admin_users_campaigns/show';
import { AdminUserCampaignCreate } from '@models/admin_users_campaigns/create';
import { AdminUserCampaignEdit } from '@models/admin_users_campaigns/edit';
import { ActionList } from '@models/actions/list';
import { ActionShow } from '@models/actions/show';
import { ActionCreate } from '@models/actions/create';
import { ActionEdit } from '@models/actions/edit';
import { ActionOwnerList } from '@models/actions_owners/list';
import { ActionOwnerShow } from '@models/actions_owners/show';
import { ActionOwnerCreate } from '@models/actions_owners/create';
import { ActionOwnerEdit } from '@models/actions_owners/edit';
import { ActionCtaAppPageList } from '@models/action_ctas/app_page/list';
import { ActionCtaAppPageShow } from '@models/action_ctas/app_page/show';
import { ActionCtaAppPageCreate } from '@models/action_ctas/app_page/create';
import { ActionCtaAppPageEdit } from '@models/action_ctas/app_page/edit';
import { ActionCtaEmailList } from '@models/action_ctas/emails/list';
import { ActionCtaEmailShow } from '@models/action_ctas/emails/show';
import { ActionCtaEmailCreate } from '@models/action_ctas/emails/create';
import { ActionCtaEmailEdit } from '@models/action_ctas/emails/edit';
import { ActionCtaExternalLinkList } from '@models/action_ctas/external_links/list';
import { ActionCtaExternalLinkShow } from '@models/action_ctas/external_links/show';
import { ActionCtaExternalLinkCreate } from '@models/action_ctas/external_links/create';
import { ActionCtaExternalLinkEdit } from '@models/action_ctas/external_links/edit';
import { ActionCtaGoogleMapsReviewList } from '@models/action_ctas/googlemaps_reviews/list';
import { ActionCtaGoogleMapsReviewShow } from '@models/action_ctas/googlemaps_reviews/show';
import { ActionCtaGoogleMapsReviewCreate } from '@models/action_ctas/googlemaps_reviews/create';
import { ActionCtaGoogleMapsReviewEdit } from '@models/action_ctas/googlemaps_reviews/edit';
import { ActionCtaPhoneCallList } from '@models/action_ctas/phone_calls/list';
import { ActionCtaPhoneCallShow } from '@models/action_ctas/phone_calls/show';
import { ActionCtaPhoneCallCreate } from '@models/action_ctas/phone_calls/create';
import { ActionCtaPhoneCallEdit } from '@models/action_ctas/phone_calls/edit';
import { ActionCtaShareLinkList } from '@models/action_ctas/share_links/list';
import { ActionCtaShareLinkShow } from '@models/action_ctas/share_links/show';
import { ActionCtaShareLinkCreate } from '@models/action_ctas/share_links/create';
import { ActionCtaShareLinkEdit } from '@models/action_ctas/share_links/edit';
import { ActionCtaSocialCommentList } from '@models/action_ctas/social_comments/list';
import { ActionCtaSocialCommentShow } from '@models/action_ctas/social_comments/show';
import { ActionCtaSocialCommentCreate } from '@models/action_ctas/social_comments/create';
import { ActionCtaSocialCommentEdit } from '@models/action_ctas/social_comments/edit';
import { ActionCtaSocialPostList } from '@models/action_ctas/social_posts/list';
import { ActionCtaSocialPostShow } from '@models/action_ctas/social_posts/show';
import { ActionCtaSocialPostCreate } from '@models/action_ctas/social_posts/create';
import { ActionCtaSocialPostEdit } from '@models/action_ctas/social_posts/edit';
import { ActionCtaWebpageList } from '@models/action_ctas/webpages/list';
import { ActionCtaWebpageShow } from '@models/action_ctas/webpages/show';
import { ActionCtaWebpageCreate } from '@models/action_ctas/webpages/create';
import { ActionCtaWebpageEdit } from '@models/action_ctas/webpages/edit';
import { BadgeList } from '@models/badges/list';
import { BadgeShow } from '@models/badges/show';
import { BadgeCreate } from '@models/badges/create';
import { BadgeEdit } from '@models/badges/edit';
import BadgeRecordRepresentation from '@models/badges/components/record_representation';
import { CampaignList } from '@models/campaigns/list';
import { CampaignShow } from '@models/campaigns/show';
import { CampaignCreate } from '@models/campaigns/create';
import { CampaignEdit } from '@models/campaigns/edit';
import { CampaignCauseList } from '@models/campaigns_causes/list';
import { CampaignCauseShow } from '@models/campaigns_causes/show';
import { CampaignCauseCreate } from '@models/campaigns_causes/create';
import { CampaignQAList } from '@models/campaign_qas/list';
import { CampaignQAShow } from '@models/campaign_qas/show';
import { CampaignQACreate } from '@models/campaign_qas/create';
import { CampaignQAEdit } from '@models/campaign_qas/edit';
import { CampaignStoryList } from '@models/campaign_stories/list';
import { CampaignStoryShow } from '@models/campaign_stories/show';
import { CampaignStoryCreate } from '@models/campaign_stories/create';
import { CampaignStoryEdit } from '@models/campaign_stories/edit';
import CampaignStoryVideoStudio from '@models/campaign_stories/components/video_studio';
import { CauseList } from '@models/causes/list';
import { CauseCreate } from '@models/causes/create';
import { CauseEdit } from '@models/causes/edit';
import { CauseShow } from '@models/causes/show';
import CRMIndex from '@models/crm/index';
import { CRMOrganizationList } from '@models/crm/crm_organizations/list';
import { CRMOrganizationShow } from '@models/crm/crm_organizations/show';
import { CRMOrganizationEdit } from '@models/crm/crm_organizations/edit';
import { CRMPersonList } from '@models/crm/crm_persons/list';
import { CRMPersonShow } from '@models/crm/crm_persons/show';
import { CRMPersonEdit } from '@models/crm/crm_persons/edit';
import { CRMTagList } from '@models/crm/crm_tags/list';
import { CRMTagShow } from '@models/crm/crm_tags/show';
import { CRMTagCreate } from '@models/crm/crm_tags/create';
import { CRMTagEdit } from '@models/crm/crm_tags/edit';
import Dashboard from '@models/dashboard';
import { DonationList } from '@models/donations/list';
import { DonationShow } from '@models/donations/show';
import { DonationCreate } from '@models/donations/create';
import { DonationEdit } from '@models/donations/edit';
import { DealList } from '@models/deals/list';
import { DealShow } from '@models/deals/show';
import { DealCreate } from '@models/deals/create';
import { DealEdit } from '@models/deals/edit';
import { DealCouponList } from '@models/deals_coupons/list';
import { DealCouponShow } from '@models/deals_coupons/show';
import { DealCouponCreate } from '@models/deals_coupons/create';
import { DealCouponEdit } from '@models/deals_coupons/edit';
import { FeatureFlagList } from '@models/feature_flags/list';
import { FeatureFlagCreate } from '@models/feature_flags/create';
import { FeatureFlagEdit } from '@models/feature_flags/edit';
import { FeatureFlagShow } from '@models/feature_flags/show';
import { InAppNotificationList } from '@models/in_app_notifications/list';
import { InAppNotificationShow } from '@models/in_app_notifications/show';
import { InAppNotificationCreate } from '@models/in_app_notifications/create';
import { InAppNotificationEdit } from '@models/in_app_notifications/edit';
import { MissionList } from '@models/missions/list';
import { MissionShow } from '@models/missions/show';
import { MissionEdit } from '@models/missions/edit';
import { MissionCreate } from '@models/missions/create';
import { MissionCauseList } from '@models/missions_causes/list';
import { MissionCauseShow } from '@models/missions_causes/show';
import { MissionCauseCreate } from '@models/missions_causes/create';
import { MissionCauseEdit } from '@models/missions_causes/edit';
import { MissionCommentList } from '@models/mission_comments/list';
import { MissionCommentEdit } from '@models/mission_comments/edit';
import { MissionCommentShow } from '@models/mission_comments/show';
import { MissionStoryList } from '@models/missions_stories/list';
import { MissionStoryShow } from '@models/missions_stories/show';
import { MissionStoryCreate } from '@models/missions_stories/create';
import { MissionStoryEdit } from '@models/missions_stories/edit';
import MissionStoryVideoStudio from '@models/missions_stories/components/video_studio';
import ProductAnalytics from '@models/product_analytics';
import { PepperPillList } from '@models/pepper_pills/list';
import { PepperPillShow } from '@models/pepper_pills/show';
import { PepperPillCreate } from '@models/pepper_pills/create';
import { PepperPillEdit } from '@models/pepper_pills/edit';
import { SubtitleList } from '@models/subtitles/list';
import { SubtitleShow } from '@models/subtitles/show';
import { SubtitleCreate } from '@models/subtitles/create';
import { SubtitleEdit } from '@models/subtitles/edit';
import { TopicList } from '@models/topics/list';
import { TopicCreate } from '@models/topics/create';
import { TopicEdit } from '@models/topics/edit';
import { TopicShow } from '@models/topics/show';
import { TopicChat } from '@models/topics/components/chat';
import { TopicCommentList } from '@models/topic_comments/list';
import { TopicCommentCreate } from '@models/topic_comments/create';
import { TopicCommentCreatePepperPill } from '@models/topic_comments/create_pepper_pill';
import { TopicCommentEdit } from '@models/topic_comments/edit';
import { TopicCommentShow } from '@models/topic_comments/show';
import { UserList } from '@models/users/list';
import { UserShow } from '@models/users/show';
import { UserCreate } from '@models/users/create';
import { UserEdit } from '@models/users/edit';
import { UserBadgeList } from '@models/users_badges/list';
import { UserBadgeShow } from '@models/users_badges/show';
import { UserBadgeCreate } from '@models/users_badges/create';
import { UserBadgeEdit } from '@models/users_badges/edit';
import { UserCampaignList } from '@models/users_campaigns/list';
import { UserCampaignCreate } from '@models/users_campaigns/create';
import { UserCampaignShow } from '@models/users_campaigns/show';
import { UsersFeatureFlagsList } from '@models/users_feature_flags/list';
import { UsersFeatureFlagsShow } from '@models/users_feature_flags/show';
import { UsersFeatureFlagsCreate } from '@models/users_feature_flags/create';
import { UsersFeatureFlagsEdit } from '@models/users_feature_flags/edit';
import { UsersPointsList } from '@models/users_points/list';
import { UsersPointsShow } from '@models/users_points/show';
import { UsersPointsCreate } from '@models/users_points/create';
import { UsersPointsEdit } from '@models/users_points/edit';
import { UsersTopicsList } from '@models/users_topics/list';
import { UsersTopicsShow } from '@models/users_topics/show';
import { UsersTopicsCreate } from '@models/users_topics/create';
import { UsersTopicsEdit } from '@models/users_topics/edit';
import { UserMissionList } from '@models/users_missions/list';
import { UserMissionCreate } from '@models/users_missions/create';
import { UserMissionShow } from '@models/users_missions/show';
import { UserProfilePromptList } from '@models/users_profiles_prompts/list';
import { UserProfilePromptShow } from '@models/users_profiles_prompts/show';
import { UserProfilePromptCreate } from '@models/users_profiles_prompts/create';
import { UserProfilePromptEdit } from '@models/users_profiles_prompts/edit';
import { UserProfileSkillList } from '@models/users_profiles_skills/list';
import { UserProfileSkillShow } from '@models/users_profiles_skills/show';
import { UserProfileSkillCreate } from '@models/users_profiles_skills/create';
import { UserProfileSkillEdit } from '@models/users_profiles_skills/edit';
import { UserProfileTagList } from '@models/users_profiles_tags/list';
import { UserProfileTagShow } from '@models/users_profiles_tags/show';
import { UserProfileTagCreate } from '@models/users_profiles_tags/create';
import { UserProfileTagEdit } from '@models/users_profiles_tags/edit';
import { UserProfileMediaList } from '@models/users_profiles_medias/list';
import { UserProfileMediaShow } from '@models/users_profiles_medias/show';
import { UserProfileMediaEdit } from '@models/users_profiles_medias/edit';
import { VideoList } from '@models/videos/list';
import { VideoShow } from '@models/videos/show';
import { VideoCreate } from '@models/videos/create';
import { VideoEdit } from '@models/videos/edit';
import { VideoScriptList } from '@models/video_scripts/list';
import { VideoScriptShow } from '@models/video_scripts/show';
import { VideoScriptCreate } from '@models/video_scripts/create';
import { VideoScriptEdit } from '@models/video_scripts/edit';
import { WinList } from '@models/wins/list';
import { WinShow } from '@models/wins/show';
import { WinCreate } from '@models/wins/create';
import { WinEdit } from '@models/wins/edit';
import CRMPersonsImport from '@models/crm/crm_persons/import';
import UserRecordRepresentation from '@models/users/components/record_representation';
import TopicRecordRepresentation from '@models/topics/components/record_representation';
import { TopicHighlightList } from '@models/topic_highlights/list';
import { TopicHighlightShow } from '@models/topic_highlights/show';
import { TopicHighlightEdit } from '@models/topic_highlights/edit';
import QRCodeGenerator from '@models/qr_code_generator';
import { ProfileSkillList } from '@models/profile_skills/list';
import { ProfileSkillShow } from '@models/profile_skills/show';
import { ProfileSkillCreate } from '@models/profile_skills/create';
import { ProfileSkillEdit } from '@models/profile_skills/edit';
import { ProfileTagCreate } from '@models/profile_tags/create';
import { ProfileTagEdit } from '@models/profile_tags/edit';
import { ProfileTagList } from '@models/profile_tags/list';
import { ProfileTagShow } from '@models/profile_tags/show';
import { ProfilePromptList } from '@models/profile_prompts/list';
import { ProfilePromptShow } from '@models/profile_prompts/show';
import { ProfilePromptCreate } from '@models/profile_prompts/create';
import { ProfilePromptEdit } from '@models/profile_prompts/edit';
import { DonationRecipientList } from '@models/donations_recipients/list';
import { DonationRecipientShow } from '@models/donations_recipients/show';
import { DonationRecipientCreate } from '@models/donations_recipients/create';
import { DonationRecipientEdit } from '@models/donations_recipients/edit';
import { UsersTransactionsCreate } from '@models/users_transactions/create';
import { UserTransactionsEdit } from '@models/users_transactions/edit';
import { UsersTransactionsList } from '@models/users_transactions/list';
import { UsersTransactionsShow } from '@models/users_transactions/show';
import ShopIndex from '@models/shop';

import { MyLayout } from './layout';
import theme from './theme';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

const App = () => {
  const isReloading = useReloadPage();

  if (isReloading) {
    return <Reloading />;
  }

  return (
    <Admin
      theme={theme}
      dataProvider={dataProvider}
      authProvider={authProvider}
      disableTelemetry
      requireAuth
      layout={MyLayout}
      queryClient={queryClient}
      dashboard={Dashboard}
    >
      {permissions => {
        const superAdmin = isSuperAdmin(permissions);
        const campaignAdmin = isCampaignAdmin(permissions);
        // const campaignManager = isCampaignManager(permissions);
        const product = isProduct(permissions);
        const readOnly = isReadOnly(permissions);

        return (
          <>
            <Resource name="product_analytics" list={ProductAnalytics} />
            <Resource name="qr_code_generator" list={QRCodeGenerator} />
            <Resource
              name="campaigns"
              list={CampaignList}
              show={CampaignShow}
              create={superAdmin || campaignAdmin ? CampaignCreate : undefined}
              edit={superAdmin || campaignAdmin ? CampaignEdit : undefined}
              recordRepresentation={campaign => campaign.name}
            />
            <Resource
              name="actions"
              list={ActionList}
              show={ActionShow}
              create={superAdmin ? ActionCreate : undefined}
              edit={superAdmin || campaignAdmin || product ? ActionEdit : undefined}
              recordRepresentation={action => {
                const actionPublishedAt = action.publishedAt
                  ? dateFormatter(action.publishedAt, { short: true })
                  : '(draft)';
                const actionName = ellipsis(
                  action.name || action.descriptionEN?.slice(0, 30).trim() || 'No name',
                  20
                );
                const actionType = action.ctaType || 'nothing';
                return `[${actionType}] ${actionName} ${actionPublishedAt}`.trim();
              }}
            >
              <Route path=":actionId/video_studio" element={<ActionVideoStudio />} />
            </Resource>
            <Resource
              name="actions_owners"
              list={ActionOwnerList}
              show={ActionOwnerShow}
              create={superAdmin || campaignAdmin ? ActionOwnerCreate : undefined}
              edit={superAdmin || campaignAdmin ? ActionOwnerEdit : undefined}
            />
            <Resource
              name="action_cta_app_pages"
              list={ActionCtaAppPageList}
              show={ActionCtaAppPageShow}
              create={!readOnly ? ActionCtaAppPageCreate : undefined}
              edit={!readOnly ? ActionCtaAppPageEdit : undefined}
            />
            <Resource
              name="action_cta_emails"
              list={ActionCtaEmailList}
              show={ActionCtaEmailShow}
              create={!readOnly ? ActionCtaEmailCreate : undefined}
              edit={!readOnly ? ActionCtaEmailEdit : undefined}
              recordRepresentation={(cta: ActionCTAEmailType) =>
                ellipsis(cta?.subject, 30) || 'New email'
              }
            />
            <Resource
              name="action_cta_phone_calls"
              list={ActionCtaPhoneCallList}
              show={ActionCtaPhoneCallShow}
              create={!readOnly ? ActionCtaPhoneCallCreate : undefined}
              edit={!readOnly ? ActionCtaPhoneCallEdit : undefined}
              recordRepresentation={(cta: ActionCTAPhoneCallType) =>
                cta && cta.phoneNumber && cta.script
                  ? `[${cta?.phoneNumber}] ${ellipsis(cta.script, 10)}`
                  : 'New phone call'
              }
            />
            <Resource
              name="action_cta_external_links"
              list={ActionCtaExternalLinkList}
              show={ActionCtaExternalLinkShow}
              create={!readOnly ? ActionCtaExternalLinkCreate : undefined}
              edit={!readOnly ? ActionCtaExternalLinkEdit : undefined}
            />
            <Resource
              name="action_cta_googlemaps_reviews"
              list={ActionCtaGoogleMapsReviewList}
              show={ActionCtaGoogleMapsReviewShow}
              create={!readOnly ? ActionCtaGoogleMapsReviewCreate : undefined}
              edit={!readOnly ? ActionCtaGoogleMapsReviewEdit : undefined}
            />
            <Resource
              name="action_cta_share_links"
              list={ActionCtaShareLinkList}
              show={ActionCtaShareLinkShow}
              create={!readOnly ? ActionCtaShareLinkCreate : undefined}
              edit={!readOnly ? ActionCtaShareLinkEdit : undefined}
            />
            <Resource
              name="action_cta_social_comments"
              list={ActionCtaSocialCommentList}
              show={ActionCtaSocialCommentShow}
              create={!readOnly ? ActionCtaSocialCommentCreate : undefined}
              edit={!readOnly ? ActionCtaSocialCommentEdit : undefined}
            />
            <Resource
              name="action_cta_social_posts"
              list={ActionCtaSocialPostList}
              show={ActionCtaSocialPostShow}
              create={!readOnly ? ActionCtaSocialPostCreate : undefined}
              edit={!readOnly ? ActionCtaSocialPostEdit : undefined}
            />
            <Resource
              name="action_cta_webpages"
              list={ActionCtaWebpageList}
              show={ActionCtaWebpageShow}
              create={!readOnly ? ActionCtaWebpageCreate : undefined}
              edit={!readOnly ? ActionCtaWebpageEdit : undefined}
            />
            <Resource
              name="badges"
              list={BadgeList}
              show={BadgeShow}
              create={!readOnly ? BadgeCreate : undefined}
              edit={!readOnly ? BadgeEdit : undefined}
              recordRepresentation={<BadgeRecordRepresentation />}
            />
            <Resource
              name="admin_users"
              list={superAdmin || campaignAdmin ? AdminUserList : undefined}
              show={superAdmin || campaignAdmin ? AdminUserShow : undefined}
              create={superAdmin || campaignAdmin ? AdminUserCreate : undefined}
              edit={superAdmin || campaignAdmin ? AdminUserEdit : undefined}
              recordRepresentation={(adminUser: AdminUserType) => adminUser.username}
            />
            <Resource
              name="admin_users_campaigns"
              list={superAdmin || campaignAdmin ? AdminUserCampaignList : undefined}
              show={superAdmin || campaignAdmin ? AdminUserCampaignShow : undefined}
              create={superAdmin || campaignAdmin ? AdminUserCampaignCreate : undefined}
              edit={superAdmin || campaignAdmin ? AdminUserCampaignEdit : undefined}
            />
            <Resource
              name="campaign_qas"
              list={CampaignQAList}
              show={CampaignQAShow}
              create={!readOnly ? CampaignQACreate : undefined}
              edit={!readOnly ? CampaignQAEdit : undefined}
            />
            <Resource
              name="campaign_stories"
              list={CampaignStoryList}
              show={CampaignStoryShow}
              create={superAdmin ? CampaignStoryCreate : undefined}
              edit={!readOnly ? CampaignStoryEdit : undefined}
            >
              <Route path="/video_studio" element={<CampaignStoryVideoStudio />} />
            </Resource>
            <Resource
              name="campaigns_causes"
              list={CampaignCauseList}
              show={CampaignCauseShow}
              create={superAdmin ? CampaignCauseCreate : undefined}
            />
            <Resource
              name="crm_organizations"
              list={CRMOrganizationList}
              show={CRMOrganizationShow}
              edit={!readOnly ? CRMOrganizationEdit : undefined}
              options={{ label: 'CRM Organizations' }}
              recordRepresentation={(org: CRMOrganizationType) =>
                `${org.name || ''}`.trim() || org.email || 'No name'
              }
            />
            <Resource
              name="crm_persons"
              list={CRMPersonList}
              show={CRMPersonShow}
              edit={!readOnly ? CRMPersonEdit : undefined}
              options={{ label: 'CRM Persons' }}
              recordRepresentation={(person: CRMPersonType) =>
                `${person.firstname || ''} ${person.lastname || ''}`.trim() ||
                person.email ||
                'No name'
              }
            >
              <Route path="/import" element={<CRMPersonsImport />} />
            </Resource>
            <Resource
              name="crm_tags"
              list={CRMTagList}
              show={CRMTagShow}
              create={!readOnly ? CRMTagCreate : undefined}
              edit={!readOnly ? CRMTagEdit : undefined}
              options={{ label: 'CRM Tags' }}
              recordRepresentation={(tag: CRMTagType) => tag.name}
            />
            <Resource
              name="causes"
              list={CauseList}
              show={CauseShow}
              create={superAdmin ? CauseCreate : undefined}
              edit={superAdmin || product ? CauseEdit : undefined}
              recordRepresentation={(cause: CauseType) => `${cause.emoji} ${cause.name}`}
            />
            <Resource
              name="deals"
              list={DealList}
              show={DealShow}
              create={superAdmin ? DealCreate : undefined}
              edit={superAdmin || campaignAdmin || product ? DealEdit : undefined}
              recordRepresentation={(deal: Deal) => `${deal.nameEN}`}
            />
            <Resource
              name="deals_coupons"
              list={DealCouponList}
              show={DealCouponShow}
              create={superAdmin ? DealCouponCreate : undefined}
              edit={superAdmin || campaignAdmin || product ? DealCouponEdit : undefined}
            />
            <Resource
              name="donations"
              list={DonationList}
              show={DonationShow}
              create={superAdmin ? DonationCreate : undefined}
              edit={superAdmin || product ? DonationEdit : undefined}
              recordRepresentation={(donation: DonationType) => `${donation.nameEN}`}
            />
            <Resource
              name="donations_recipients"
              list={DonationRecipientList}
              show={DonationRecipientShow}
              create={
                superAdmin || campaignAdmin || product
                  ? DonationRecipientCreate
                  : undefined
              }
              edit={
                superAdmin || campaignAdmin || product ? DonationRecipientEdit : undefined
              }
            />
            <Resource
              name="profile_skills"
              list={ProfileSkillList}
              show={ProfileSkillShow}
              create={superAdmin ? ProfileSkillCreate : undefined}
              edit={superAdmin || product ? ProfileSkillEdit : undefined}
              recordRepresentation={(profileSkill: ProfileSkillType) =>
                `${profileSkill.emoji} ${profileSkill.labelEN}`
              }
            />
            <Resource
              name="profile_tags"
              list={ProfileTagList}
              show={ProfileTagShow}
              create={superAdmin ? ProfileTagCreate : undefined}
              edit={superAdmin || product ? ProfileTagEdit : undefined}
              recordRepresentation={(profileTag: ProfileTagType) =>
                `${profileTag.emoji} ${profileTag.labelEN}`
              }
            />
            <Resource
              name="profile_prompts"
              list={ProfilePromptList}
              show={ProfilePromptShow}
              create={superAdmin ? ProfilePromptCreate : undefined}
              edit={superAdmin || product ? ProfilePromptEdit : undefined}
              recordRepresentation={(profilePrompt: ProfilePromptType) =>
                `${profilePrompt.questionEN.slice(0, 30)}`
              }
            />
            <Resource
              name="feature_flags"
              list={FeatureFlagList}
              show={FeatureFlagShow}
              create={superAdmin || product ? FeatureFlagCreate : undefined}
              edit={superAdmin || product ? FeatureFlagEdit : undefined}
              recordRepresentation={ff => ff.name}
            />
            <Resource
              name="missions"
              list={MissionList}
              show={MissionShow}
              create={!readOnly ? MissionCreate : undefined}
              edit={!readOnly ? MissionEdit : undefined}
              recordRepresentation={(mission: MissionType) =>
                mission.name || mission.titleEN
              }
            />
            <Resource
              name="missions_causes"
              list={MissionCauseList}
              show={MissionCauseShow}
              create={!readOnly ? MissionCauseCreate : undefined}
              edit={!readOnly ? MissionCauseEdit : undefined}
              recordRepresentation={missionCause => missionCause.id}
            />
            <Resource
              name="missions_stories"
              list={MissionStoryList}
              show={MissionStoryShow}
              create={!readOnly ? MissionStoryCreate : undefined}
              edit={!readOnly ? MissionStoryEdit : undefined}
            >
              <Route path="/video_studio" element={<MissionStoryVideoStudio />} />
            </Resource>
            <Resource
              name={'mission_comments'}
              list={MissionCommentList}
              show={MissionCommentShow}
              edit={superAdmin || product ? MissionCommentEdit : undefined}
              recordRepresentation={c => c.message}
            />
            <Resource
              name="in_app_notifications"
              list={InAppNotificationList}
              show={InAppNotificationShow}
              create={
                superAdmin || campaignAdmin || product
                  ? InAppNotificationCreate
                  : undefined
              }
              edit={
                superAdmin || campaignAdmin || product ? InAppNotificationEdit : undefined
              }
              recordRepresentation={notif => notif.titleEN}
              options={{ label: 'In-App Notifications' }}
            />
            <Resource
              name="pepper_pills"
              list={PepperPillList}
              show={PepperPillShow}
              create={!readOnly ? PepperPillCreate : undefined}
              edit={!readOnly ? PepperPillEdit : undefined}
              recordRepresentation={pill => ellipsis(pill.titleEN, 30) || ''}
            />
            <Resource
              name="subtitles"
              list={SubtitleList}
              show={SubtitleShow}
              create={!readOnly ? SubtitleCreate : undefined}
              edit={!readOnly ? SubtitleEdit : undefined}
            />
            <Resource
              name="topics"
              list={TopicList}
              show={TopicShow}
              create={superAdmin || campaignAdmin || product ? TopicCreate : undefined}
              edit={superAdmin || campaignAdmin || product ? TopicEdit : undefined}
              recordRepresentation={<TopicRecordRepresentation />}
            >
              <Route path=":topicId/chat" element={<TopicChat />} />
            </Resource>
            <Resource
              name="topic_comments"
              list={TopicCommentList}
              show={TopicCommentShow}
              create={superAdmin || campaignAdmin ? TopicCommentCreate : undefined}
              edit={superAdmin || campaignAdmin ? TopicCommentEdit : undefined}
              recordRepresentation={tc => ellipsis(tc.message, 30) || tc.id}
            >
              <Route path="pepper_pill" element={<TopicCommentCreatePepperPill />} />
            </Resource>
            <Resource
              name="topic_highlights"
              list={TopicHighlightList}
              show={TopicHighlightShow}
              edit={superAdmin || campaignAdmin ? TopicHighlightEdit : undefined}
            />
            <Resource
              name="users"
              list={UserList}
              show={UserShow}
              create={superAdmin ? UserCreate : undefined}
              edit={superAdmin || product ? UserEdit : undefined}
              recordRepresentation={<UserRecordRepresentation />}
            />
            <Resource
              name="users_feature_flags"
              list={UsersFeatureFlagsList}
              show={UsersFeatureFlagsShow}
              create={superAdmin || product ? UsersFeatureFlagsCreate : undefined}
              edit={superAdmin || product ? UsersFeatureFlagsEdit : undefined}
            />
            <Resource
              name="users_points"
              list={UsersPointsList}
              show={UsersPointsShow}
              create={superAdmin ? UsersPointsCreate : undefined}
              edit={superAdmin ? UsersPointsEdit : undefined}
            />
            <Resource
              name="users_transactions"
              list={UsersTransactionsList}
              show={UsersTransactionsShow}
              create={superAdmin ? UsersTransactionsCreate : undefined}
              edit={superAdmin ? UserTransactionsEdit : undefined}
            />
            <Resource
              name="users_profiles_medias"
              list={UserProfileMediaList}
              show={UserProfileMediaShow}
              edit={UserProfileMediaEdit}
            />
            <Resource
              name="users_profiles_skills"
              list={UserProfileSkillList}
              show={UserProfileSkillShow}
              create={UserProfileSkillCreate}
              edit={UserProfileSkillEdit}
            />
            <Resource
              name="users_profiles_prompts"
              list={UserProfilePromptList}
              show={UserProfilePromptShow}
              create={UserProfilePromptCreate}
              edit={UserProfilePromptEdit}
            />
            <Resource
              name="users_profiles_tags"
              list={UserProfileTagList}
              show={UserProfileTagShow}
              create={UserProfileTagCreate}
              edit={UserProfileTagEdit}
            />
            <Resource
              name="users_topics"
              list={UsersTopicsList}
              show={UsersTopicsShow}
              create={superAdmin || campaignAdmin ? UsersTopicsCreate : undefined}
              edit={superAdmin || campaignAdmin ? UsersTopicsEdit : undefined}
            />
            <Resource
              name="users_badges"
              list={UserBadgeList}
              show={UserBadgeShow}
              create={
                superAdmin || campaignAdmin || product ? UserBadgeCreate : undefined
              }
              edit={superAdmin || campaignAdmin || product ? UserBadgeEdit : undefined}
            />
            <Resource
              name="users_campaigns"
              list={UserCampaignList}
              show={UserCampaignShow}
              create={
                superAdmin || campaignAdmin || product ? UserCampaignCreate : undefined
              }
            />
            <Resource
              name="users_missions"
              list={UserMissionList}
              show={UserMissionShow}
              create={
                superAdmin || campaignAdmin || product ? UserMissionCreate : undefined
              }
            />
            <Resource
              name="videos"
              list={VideoList}
              show={VideoShow}
              create={superAdmin ? VideoCreate : undefined}
              edit={superAdmin ? VideoEdit : undefined}
              recordRepresentation={(video: VideoType) => video.name}
            />
            <Resource
              name="video_scripts"
              list={VideoScriptList}
              show={VideoScriptShow}
              create={!readOnly ? VideoScriptCreate : undefined}
              edit={!readOnly ? VideoScriptEdit : undefined}
              recordRepresentation={(video: VideoScriptType) =>
                video.script?.slice(0, 50)
              }
            />
            <Resource
              name="wins"
              list={WinList}
              show={WinShow}
              create={!readOnly ? WinCreate : undefined}
              edit={!readOnly ? WinEdit : undefined}
              recordRepresentation={(win: WinType) =>
                (win.shortCopyEn && win.shortCopyEn.slice(0, 30).trim() + '...') || ''
              }
            />
            <CustomRoutes>
              <Route path="/shop" element={<ShopIndex />} />
              <Route path="/crm" element={<CRMIndex />} />
              <Route path="campaigns-analytics" element={<CampaignsAnalytics />} />
            </CustomRoutes>
          </>
        );
      }}
    </Admin>
  );
};

export default App;
