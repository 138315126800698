import { DealCoupon } from './deal_coupon';
import { UserType } from './user';

export enum DealType {
  AffiliateLink = 'AFFILIATE_LINK',
  CouponCode = 'COUPON_CODE',
}

export type Deal = {
  id: string;
  nameEN: string;
  nameFR: string;
  taglineFR: string;
  taglineEN: string;
  descriptionFR: string;
  descriptionEN: string;
  type: DealType;
  brandNameEN: string;
  brandNameFR: string;
  brandDescriptionEN: string;
  brandDescriptionFR: string;
  brandUrl?: string;
  coinValue: number;
  avatarUrl: string;
  thumbnailUrl: string;
  affiliateUrl?: string;

  createdAt: string;
  updatedAt: string;
  expiredAt: string;
  launchedAt: string;

  users?: UserType[];
  coupons?: DealCoupon[];
};
