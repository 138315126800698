import React from 'react';
import {
  DateField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';


const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'product']);
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit && <EditButton label="Edit" variant="outlined" />}
    />
  );
};


export const DealCouponShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField source="code" />
      <ReferenceField label="Deal" source="dealId" reference="deals" />
      <DateField label="Created At" source="createdAt" showTime />
      <DateField label="Refeemed At" source="redeemedAt" showTime />
    </SimpleShowLayout>
  </Show>
);

