import * as React from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';

const ListActions = (
  <ResourceTitleActionBar
    mode="list"
    title="Deal Coupons"
    actions={<CreateButton variant="outlined" />}
  />
);

export const DealCouponList = () => (
  <List
    exporter={false}
    sort={{ field: 'createdAt', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField label="Code" source="code" />
      <ReferenceField label="Deal" source="dealId" reference="deals" />
      <DateField label="Created At" source="createdAt" showTime />
      <DateField label="Refeemed At" source="redeemedAt" showTime />
    </Datagrid>
  </List>
);
