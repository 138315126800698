import React, { useCallback } from 'react';
import {
  Button,
  DateTimeInput,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { DealType } from 'types/deal.d';
import { useFormContext } from 'react-hook-form';
import TranslateIcon from '@mui/icons-material/Translate';

import { useOpenAI } from '@hooks/useOpenAI';
import UrlInput from '@components/inputs/url_input';
import Callout from '@components/callout';
import LoadingAnimation from '@components/svgs/loading_animation';
import { SpacingStyle } from '@styles/variables';

interface DealFormProps {
  mode: 'create' | 'edit';
}

const TransformButton = () => {
    const { isLoading: isOpenAILoading, translateText } = useOpenAI();
    const { getValues, setValue } = useFormContext();

    const handleTranslation = useCallback(async () => {
      const { nameEN, taglineEN, descriptionEN, brandNameEN, brandDescriptionEN } = getValues();
      if (nameEN) {
        const nameFR = await translateText({ text: nameEN, from: 'en', to: 'fr' });
        setValue('nameFR', nameFR);
      }
      if (taglineEN) {
        const taglineFR = await translateText({ text: taglineEN, from: 'en', to: 'fr' });
        setValue('taglineFR', taglineFR);
      }
      if (descriptionEN) {
        const descriptionFR = await translateText({
          text: descriptionEN,
          from: 'en',
          to: 'fr',
        });
        setValue('descriptionFR', descriptionFR);
      }
      if (brandNameEN) {
        setValue('brandNameFR', brandNameEN);
      }
      if (brandDescriptionEN) {
        const brandDescriptionFR = await translateText({
          text: brandDescriptionEN,
          from: 'en',
          to: 'fr',
        });
        setValue('brandDescriptionFR', brandDescriptionFR);
      }
    }, [translateText, getValues, setValue]);

  return (
    <Button
      onClick={handleTranslation}
      disabled={isOpenAILoading}
      startIcon={isOpenAILoading ? <LoadingAnimation width={18} height={18}/> : <TranslateIcon />}
      label="Transalte from English to French"
      variant='outlined'
    />
  );
};

const DealForm: React.FC<DealFormProps> = ({ mode }) => {


  return (
    <SimpleForm>
      <ImageField source="avatarUrl" title="Brand avatar" />
      <ImageInput
        label="Brand avatar"
        source="avatar"
        accept="image/*"
        maxSize={4000000}
        validate={mode === 'create' ? [required()] : []}
        helperText="A squared logo with a colored background is recommended"
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageField source="thumbnailUrl" title="Thumbnail" />
      <ImageInput
        label="Deal's thumbnail"
        source="thumbnail"
        accept="image/*"
        maxSize={4000000}
        validate={mode === 'create' ? [required()] : []}
        helperText="A landscape image, showcasing the item(s) users will be able to get, is recommended"
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <NumberInput
        source="coinValue"
        validate={required()}
        fullWidth
        placeholder="5,000"
      />

      <SelectInput
        source="type"
        label="Deal type"
        choices={[
          { id: DealType.AffiliateLink, name: 'Affiliate Link' },
          { id: DealType.CouponCode, name: 'Coupon Code' },
        ]}
        validate={required()}
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.type === DealType.CouponCode ? (
            <Callout backgroundColor="grey" emoji="🏷️">
              You'll be asked to enter all coupon codes after saving the deal.
            </Callout>
          ) : formData.type === DealType.AffiliateLink ? (
            <UrlInput
              source="affiliateUrl"
              placeholder="https://"
              helperText="Must be a custom link given by the brand"
            />
          ) : null
        }
      </FormDataConsumer>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: SpacingStyle.normal,
          width: '100%',
        }}
      >
        <div style={{ width: '100%' }}>
          <h3>🇺🇸 English</h3>
          <TextInput
            source="nameEN"
            label="Deal's name EN"
            validate={required()}
            fullWidth
          />
          <TextInput
            source="taglineEN"
            label="Deal's tagline EN"
            validate={required()}
            fullWidth
          />
          <TextInput
            source="descriptionEN"
            label="Deal's description EN"
            multiline
            validate={required()}
            fullWidth
          />
          <TextInput
            source="brandNameEN"
            label="Brand's name EN"
            validate={required()}
            fullWidth
          />
          <TextInput
            source="brandDescriptionEN"
            label="Brand's description EN"
            multiline
            validate={required()}
            fullWidth
          />
        </div>

        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <h3>🇫🇷 French</h3>
            <TransformButton />
          </div>
          <TextInput
            source="nameFR"
            label="Deal's name FR"
            validate={required()}
            fullWidth
          />
          <TextInput
            source="taglineFR"
            label="Deal's tagline FR"
            validate={required()}
            fullWidth
          />
          <TextInput
            source="descriptionFR"
            label="Deal's description FR"
            multiline
            validate={required()}
            fullWidth
          />
          <TextInput
            source="brandNameFR"
            label="Brand's name FR"
            validate={required()}
            fullWidth
          />
          <TextInput
            source="brandDescriptionFR"
            label="Brand's description FR"
            multiline
            validate={required()}
            fullWidth
          />
        </div>
      </div>

      <UrlInput
        source="brandUrl"
        label="Brand's website URL"
        placeholder="https://"
        fullWidth
      />

      <DateTimeInput source="launchedAt" />
      <DateTimeInput source="expiredAt" />
    </SimpleForm>
  );
};

export default DealForm;
